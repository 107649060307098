var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "wuwow-card"
  }, [_vm._m(0), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-12 col-lg-4"
  }, [_c('a', {
    staticClass: "d-flex flex-column align-items-center justify-content-center",
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function ($event) {
        return _vm.link('self_test_room');
      }
    }
  }, [_c('img', {
    staticStyle: {
      "width": "250px"
    },
    attrs: {
      "src": require('@/assets/guide_self_test.svg')
    }
  }), _c('span', [_vm._v("自我測試教室")])])]), _c('div', {
    staticClass: "col-1 my-3 my-lg-0 -mx-2"
  }), _c('div', {
    staticClass: "col-12 col-lg-4"
  }, [_c('a', {
    staticClass: "d-flex flex-column align-items-center justify-content-center",
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function ($event) {
        return _vm.link('self_test');
      }
    }
  }, [_c('img', {
    staticStyle: {
      "width": "250px"
    },
    attrs: {
      "src": require('@/assets/guide_self_test_room.svg')
    }
  }), _c('span', [_vm._v("自助系統測試")])])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v("新手上路")])]);

}]

export { render, staticRenderFns }