<template lang="pug">
  div
    .wuwow-card
      .wuwow-card-head
        h4.wuwow-card-title 新手上路
      .wuwow-card-body
        .row.justify-content-center
          .col-12.col-lg-4
            a.d-flex.flex-column.align-items-center.justify-content-center(href="javascript:;" @click="link('self_test_room')")
              img(style="width: 250px" :src="require('@/assets/guide_self_test.svg')")
              span 自我測試教室
          .col-1.my-3.my-lg-0.-mx-2
          .col-12.col-lg-4
            a.d-flex.flex-column.align-items-center.justify-content-center(href="javascript:;" @click="link('self_test')")
              img(style="width: 250px" :src="require('@/assets/guide_self_test_room.svg')")
              span 自助系統測試
</template>
<script type="text/javascript">
export default {
  name: 'Guide',

  components: {
  },

  methods: {
    link(payload) {
      const aElement = document.createElement('a');
      if (payload === 'self_test_room') {
        aElement.setAttribute('target', '_blank');
        aElement.setAttribute('href', '/dojo/account/system-testing-url');
      } else if (payload === 'self_test') {
        aElement.setAttribute('href', '/dojo/account/selfTest');
      }
      aElement.click();
    },
  },
};
</script>
